@charset 'utf-8';
@import '../_env.scss';

.site-header {
	.navigation {
		.toggle {
			&:before {
				@media (max-width: $breakpoint) {
					background-color: transparent;
				}
			}
		}
	}
}

.page-home {
	background: url(/img/main.png) 50% 0 no-repeat;
	background-size: 100% auto;

	.site-header {
		.menu {
			@media (min-width: $breakpoint) {
				display: none;
			}
		}

		.related-site {
			top: 55px;
		}

		&.scrolled {
			.menu {
				display: block;
			}
		}
	}

	.page {
		> .header {
			height: 58.59375vw;
			text-align: center;

			.title {
				margin: 20px 0 0;
				padding: 0;
				font-weight: 400;
				font-size: 2.25rem;
				line-height: 1.55555555556;

				@media (max-width: $breakpoint) {
					margin-top: 0;
					font-size: 1.25rem;
				}
			}

			.button-block {
				margin: 40px 0 0;

				@media (max-width: $breakpoint) {
					margin-top: 80px;
				}
			}

			.button {
				margin: 0 auto;

				@media (max-width: $breakpoint) {
					max-width: 220px;
				}
			}
		}

		> .body {
			background: transparent;
		}
	}
}



.home-index-3 {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -70px;
	padding: 0;
	list-style-type: none;

	@media (max-width: $breakpoint) {
		margin-left: -10px;
		margin-right: -10px;
	}

	> li {
		box-sizing: border-box;
		margin: 20px 0 0 !important;
		padding: 0 10px;
		width: 33.3333%;

		@media (max-width: $breakpoint) {
			width: 50%;
		}
	}
}



.home-index-2 {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -70px;
	padding: 0;
	list-style-type: none;

	@media (max-width: $breakpoint + 20px) {
		margin-left: -60px;
		margin-right: -60px;
	}

	@media (max-width: $breakpoint) {
		margin-left: -10px;
		margin-right: -10px;
	}

	> li {
		box-sizing: border-box;
		margin: 20px 0 0 !important;
		padding: 0 10px;
		width: 50%;
	}

	.home-index-item {
		figcaption {
			&:after {
				@media (max-width: $breakpoint) {
					margin-top: 7px;
				}
			}

			.title {
				min-height: 0;
			}
		}
	}
}



.home-index-item-wrap {
	border-radius: 6px;
	background: #fff;
}



.home-index-item {
	display: block;
	position: relative;
	color: #fff;
	text-align: center;
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

	.thumb {
		display: block;
		max-width: 100%;
		border-radius: 6px;
	}

	figcaption {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;

		> br {
			display: none;
		}

		&:after {
			content: "";
			display: block;
			margin: 20px auto 0;
			width: 18px;
			height: 18px;
			background: url(/img/icon_index.png) 0 0 no-repeat;

			@media (max-width: $breakpoint) {
				margin-top: 10px;
			}
		}
	}

	.title {
		display: flex;
		min-height: 3.333333em;
		font-size: 1.375rem;
		font-weight: 700;
		line-height: 1.63636363636;
		align-items: flex-end;
		justify-content: center;

		@media (max-width: $breakpoint) {
			font-size: 1rem;
			line-height: 1.5;
			min-height: 3em;

			&.thin {
				min-height: 2.4em;
			}
		}
	}

	.subtitle {
		display: block;
		font-size: .75rem;

		@media (max-width: $breakpoint) {
			&.thin {
				line-height: 1.25;
			}
		}
	}
}



.home-index-item-2 {
	display: block;
	position: relative;
	color: $color_text;
	text-align: center;

	&:hover {
		opacity: 1;

		figcaption {
			opacity: .5;
		}
	}

	.thumb {
		display: block;
		max-width: 100%;
	}

	figcaption {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;

		> br {
			display: none;
		}

		&:after {
			content: "";
			display: block;
			margin: 60px auto 0;
			width: 18px;
			height: 18px;
			background: url(/common/img/icon_index.png) 0 0 no-repeat;

			@media (max-width: $breakpoint) {
				margin-top: 30px;
			}
		}
	}

	.title {
		display: flex;
		min-height: 4em;
		font-size: 1.125rem;
		font-weight: 700;
		line-height: 1.63636363636;
		align-items: flex-end;
		justify-content: center;

		@media (max-width: $breakpoint) {
			margin-top: 25px;
			font-size: .875rem;
			line-height: 1.5;
			min-height: 1.5em;
		}
	}

	.subtitle {
		display: block;
		font-size: .75rem;
	}
}


.block-bg {
	padding-top: 40px;
	padding-bottom: 60px;
}
